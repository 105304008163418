<!--  -->
<template>
  <div class="add-meeting-room">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '会议室管理',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <van-form class="add-metting-form" @failed="onFailed" ref="addMettingRoom">
      <p class="top-label"><small class="is-star">*</small>会议室所在地</p>
      <van-field
        readonly
        clickable
        name="address"
        :value="roomForm.address"
        placeholder="请选择会议室所在地"
        @click="showAddressPicker = true"
        :rules="[{ required: true, message: '' }]"
        :disabled="code ? true : false"
      />
      <!-- 地点选择 -->
      <van-popup v-model="showAddressPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="optionAddress"
          @confirm="onConfirm"
          @cancel="showAddressPicker = false"
        />
      </van-popup>
      <p class="top-label"><small class="is-star">*</small>会议室名称</p>
      <van-field
        v-model.trim="roomForm.mrName"
        maxlength="20"
        name="mrName"
        placeholder="请填写会议室名称"
        :rules="[{ required: true, message: '' }]"
      />
      <p class="top-label"><small class="is-star">*</small>会议室最大人数</p>
      <van-field
        v-model.trim="roomForm.peopleNumber"
        maxlength="9"
        name="peopleNumber"
        placeholder="请填写会议室最大人数"
        :rules="[
          { required: true, message: '' },
          { pattern: /^\+?[1-9]\d*$/, message: '输入格式有误，请输入正整数' },
        ]"
      />
      <p class="top-label">会议室设备</p>

      <van-checkbox-group
        v-model="checkList"
        class="group"
        @change="changeCheck"
      >
        <van-checkbox
          :name="item"
          shape="square"
          v-for="(item, i) in checkOptions"
          :key="i"
          :class="checkList.includes(item) ? 'selected' : ''"
          >{{ item }}</van-checkbox
        >
      </van-checkbox-group>
      <van-checkbox-group class="group">
        <van-checkbox shape="square" @click="dialogEquipment = true"
          ><van-icon name="plus" /></van-checkbox
      ></van-checkbox-group>
      <!-- 添加设备 -->
      <van-popup v-model="dialogEquipment" round>
        <div class="address-popup">
          <div class="title">新增设备</div>
          <van-form @failed="onFailed" ref="addEquipment">
            <van-field
              class="input-text"
              name="name"
              v-model.trim="equipmentForm.name"
              rows="1"
              maxlength="20"
              type="textarea"
              placeholder="请输入设备名称"
              :rules="[{ required: true, message: '' }]"
          /></van-form>

          <div class="opt-text">
            <span @click="cancelEquipment">取消</span>
            <span @click="sureEquipment" :loading="loading" class="sure"
              >确定</span
            >
          </div>
        </div>
      </van-popup>

      <p class="top-label">会议室图片</p>
      <van-uploader
        class="upload-img"
        :max-size="2 * 1024 * 1024"
        :before-read="beforeRead"
        :after-read="afterRead"
        @oversize="onOversize"
        :max-count="1"
      >
        <van-image
          v-if="imgUrl"
          width="1.6rem"
          height="1.6rem"
          class="img-upload"
          fit="cover"
          :src="imgUrl"
          style="margin-top: 0.1rem"
        />
      </van-uploader>
    </van-form>
    <div class="btn-opt">
      <p class="submit-btn" :loading="loading" @click="submitForm">提交</p>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  addMeetingAddress,
  meetingRoomList,
  uploadImg,
  addMeeting,
  addressList,
  delMeeting,
  editMeeting,
  noAdderessMeetingRoomList,
  MeetingRoomInfo,
} from "@/api/smartMeetingRoom/index.js";
import { Toast } from "vant";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      code: "",
      showAddressPicker: false,
      optionAddress: [], //地点列表
      imgUrl: "",
      roomForm: {
        address: "",
        addrId: "",
        equipments: "",
        mrName: "",
        mrPic: "",
        peopleNumber: "",
        mrCode: "",
      },
      equipmentForm: { name: "" },
      dialogEquipment: false,
      checkList: [], //选择设备
      checkOptions: ["白板", "视频", "音频"], //设备
      loading: false,
    };
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
    if (this.$route.query) {
      this.code = this.$route.query.code;
    }
    this.getAddressList();
  },

  methods: {
    //获取详情
    getInfo() {
      MeetingRoomInfo({ mrCode: this.code }).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.roomForm = {
              address: "",
              addrId: data.addrId,
              equipments: data.equipments,
              mrName: data.mrName,
              mrPic: data.mrPic,
              peopleNumber: data.peopleNumber,
              mrCode: data.mrCode,
            };
            //地址
            if (data.addrId) {
              let obj = this.optionAddress.find((e) => {
                if (data.addrId == e.id) {
                  return e;
                }
              });
              if (obj) {
                this.roomForm.address = obj.text;
              }
            }
            //设备
            if (data.equipments) {
              this.checkList = data.equipments.split(",");
              let mixArr = this.checkOptions.concat(this.checkList);
              this.checkOptions = [...new Set(mixArr)];
            }
            if (data.mrPic) {
              this.imgUrl = data.mrPic;
            }
          }
        }
      });
    },
    // 获取地址
    getAddressList() {
      addressList().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            if (data && data.length > 0) {
              let arr = [];
              data.map((e) => {
                arr.push({
                  text: e.name,
                  id: e.id,
                });
              });
              this.optionAddress = arr;
              if (this.code) {
                this.getInfo();
              }
            }
          }
        }
      });
    },
    //获取会议室详情

    onFailed() {},
    //添加会议室
    onConfirm(value) {
      this.roomForm.address = value.text;
      this.roomForm.addrId = value.id;
      this.showAddressPicker = false;
    },
    changeCheck(e) {
      this.checkList = e;
    },
    cancelEquipment() {
      this.equipmentForm = {
        name: "",
      };
      this.dialogEquipment = false;
    },
    sureEquipment() {
      this.$refs.addEquipment.validate(["name"]).then((res) => {
        this.checkOptions.unshift(this.equipmentForm.name);
        this.dialogEquipment = false;
        this.equipmentForm = {
          name: "",
        };
      });
    },
    onOversize(file) {
      Toast("文件大小不能超过 2M");
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type == "image/jpeg" || file.type == "image/png") {
        return true;
      }
      Toast("请上传 jpg/png 格式图片");
      return false;
    },
    afterRead(file) {
      this.loading = true;
      let form = new FormData();
      form.append("file", file.file);
      Toast.loading({
        message: "文件上传中...",
        forbidClick: true,
        duration: 0,
      });

      uploadImg(form).then((res) => {
        Toast.clear();
        this.loading = false;
        if (res) {
          // console.log(res, "上传图片");
          let { data, code, msg } = res;
          if (code == 200) {
            Toast({
              type: "success",
              message: msg,
            });
            this.imgUrl = data.fileUrl;
          }
        }
      });
    },
    //提交校验
    submitForm() {
      this.loading = true;
      this.$refs.addMettingRoom
        .validate(["address", "equipments", "mrName", "mrPic", "peopleNumber"])
        .then((res) => {
          if (this.code) {
            const params = {
              address: this.roomForm.address,
              addrId: this.roomForm.addrId,
              equipments:
                this.checkList.length > 0 ? this.checkList.toString() : "",
              mrName: this.roomForm.mrName,
              mrPic: this.imgUrl,
              peopleNumber: this.roomForm.peopleNumber,
              mrCode: this.roomForm.mrCode,
            };

            this.edit(params);
          } else {
            const params = {
              address: this.roomForm.address,
              addrId: this.roomForm.addrId,
              equipments:
                this.checkList.length > 0 ? this.checkList.toString() : "",
              mrName: this.roomForm.mrName,
              mrPic: this.imgUrl,
              peopleNumber: this.roomForm.peopleNumber,
              mrCode: this.roomForm.mrCode,
            };
            this.add(params);
          }
        });
    },
    //编辑
    edit(params) {
      editMeeting(params).then((res) => {
        this.loading = false;
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            Toast({
              type: "success",
              message: msg,
            });
            this.$router.go(-1);
          }
        }
      });
    },
    //新增
    add(params) {
      addMeeting(params).then((res) => {
        this.loading = false;
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            Toast({
              type: "success",
              message: msg,
            });
            this.$router.go(-1);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.add-meeting-room {
  width: 100%;
  height: auto;
  min-height: 100%;
  background: #fff;
  position: relative;
  padding: 0.3rem;
  .add-metting-form {
    min-height: 11.3rem;
    width: 100%;
    .top-label {
      margin-top: 0.3rem;
      width: 100%;
      font-size: 0.28rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .upload-img {
      margin-top: 0.1rem;
    }
    .group {
      display: flex;
      flex-direction: row;
      width: 6.9rem;
      flex-wrap: wrap;
      .van-checkbox {
        min-width: 1.28rem;
        padding: 0 0.1rem;
        height: 0.6rem;
        border-radius: 0.5rem;
        background: #ffffff;
        margin-right: 0.3rem;
        border: 0.01rem solid #0094ee;
        margin-top: 0.1rem;
        ::v-deep .van-checkbox__icon {
          display: none;
        }
        ::v-deep .van-checkbox__label {
          min-width: 1rem;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 0.24rem;
          font-weight: 400;
          color: #0094ee;
          margin-left: 0rem;
        }
      }
      .selected {
        background: #0094ee;
        ::v-deep .van-checkbox__label {
          color: #fff;
        }
      }
    }
    .address-popup {
      height: 3.94rem;
      background: #ffffff;
      border-radius: 0.16rem;
      width: 5.7rem;
      padding: 0.2rem;
      .title {
        height: 0.45rem;
        font-size: 0.32rem;
        font-weight: 500;
        color: #333333;
        line-height: 0.45rem;
        text-align: center;
      }
      .input-text {
        margin-top: 0.24rem;
        width: 5.3rem;
        height: 2.09rem;
        background: #f4f9fb;
        border-radius: 0.16rem;
      }
      .opt-text {
        margin-top: 0.24rem;
        display: flex;
        width: 100%;
        flex-direction: row;

        span {
          width: 50%;
          height: 0.45rem;
          font-size: 0.32rem;
          font-weight: 400;
          color: #0094ee;
          line-height: 0.45rem;
          text-align: center;
        }
        .sure {
          border-left: 0.01rem solid #f0f0f0;
        }
      }
    }
  }
  .btn-opt {
    width: 100%;
    height: 0.8rem;

    .submit-btn {
      height: 0.8rem;
      width: 6.9rem;
      background: #0094ee;
      border-radius: 0.4rem;
      font-weight: 400;
      color: #fff;
      font-size: 0.26rem;
      text-align: center;
      line-height: 0.8rem;
    }
  }
  .is-star {
    color: red;
  }
}
</style>
